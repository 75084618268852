@import './variables.scss';

h1, h2, h3, p, div, label {
  font-family: Amarante;
}

a {
  border-bottom: 0!important;

  &:hover {
    text-decoration: none!important;
  }

  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

.button {
  box-shadow: inset 0 0 0 2px $color-white;
  color: $color-white !important;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.quote {
  font-style: italic;
}

.container-info {
  padding: 2em 0 2em 0 ;
  background-color: #4686a0;
  color: rgba(255, 255, 255, 0.75);
  background-attachment: fixed, fixed, fixed;
  background-image: url("../images/overlay2.png"), url("../images/overlay3.svg"), linear-gradient(45deg, #9dc66b 5%, #4fa49a 30%, #4361c2);
  background-position: top left, center center, center center;
  background-size: auto, cover, cover;
  overflow: hidden;
  position: relative;
  margin: 0px;
  width: 100%;

  h2, h3 {
    color: rgba(255, 255, 255, 0.75);
    font-weight: bold;
  }
}

.justified {
  text-align: justify;
  text-justify: inter-word;

  .major {
    text-align: left;
    text-justify: auto;
  }
}

.section-data {
  padding: 2em 0 2em 0 ;
}

.row-centered {
  text-align:center;
}

.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;
}

.white-border-round {
  border: 2px;
  border-color: white;
  border-radius: 10px;
  box-shadow: inset 0 0 0 2px #ffffff;
}

@import './banner.scss';

@import './mobile-navigation.scss';

@import './divider.scss';

@import './strefa-szkolen.scss';

.rotation-animation {
  cursor: pointer!important;

  &:hover {
    -webkit-transition: -webkit-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
    -webkit-transform: rotate(405deg)!important;
    transform: rotate(405deg)!important;
  }
}

.test-rotate {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);;
  transform: translate(-50%, -50%) rotate(-45deg);
  line-height: normal;
  font-size: 0.75em;
}

@media only screen and (min-width: 736px) {
  .test-rotate {
    font-size: 1em;
  }
}

.go-top {
  position: fixed;
  top: 1em;
  right: 1em;
  display: none;
  z-index: 100;
  color: black;
  cursor: pointer;
}

header.major:after {
  background: rgba(255, 255, 255, 0.75);
}

.my-background1 {
  background-image: url(../images/overlay2.png), url(../images/overlay3.svg), linear-gradient(90deg, #2c7fba 5%, #205d89 30%, #133853);
}
.my-background2 {
  background: #205d89!important; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(0deg, #294e70, #205d89, #6a9bc8)!important; /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(0deg, #294e70, #205d89, #6a9bc8)!important; /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(0deg, #294e70, #205d89, #6a9bc8)!important; /* For Firefox 3.6 to 15 */
  background: linear-gradient(0deg, #294e70, #205d89,#6a9bc8)!important; /* Standard syntax (must be last) */
}

#bar-menu {
  display: none;
}
@media only screen and (max-width: 767px) {
  #banner-menu {
    display: none;
  }
  #bar-menu {
    display: block;
  }
  #banner-intro {
    top: 15%;
  }
}
/* Inverted modal */
.modal-body h2, h3 {
  color: #010340;
  font-weight: bold;
}
.modal-body p, .modal-body ul, .modal-body li {
  color: #010340
}
.modal-footer .button {
  box-shadow: inset 0 0 0 2px #010340;
  color: #010340 !important;
}
.modal-footer {
  border-top-color: #010340;
}
.modal-body header.major:after {
  background-color: #010340;
}
/* Colors of icons, rombs */
.icon.style1 {
  border-color: #c3e895!important;
}
.icon.style2 {
  border-color: #8addaa!important;
}
.icon.style3 {
  border-color: #6bd4c8!important;
}
.icon.style4 {
  border-color: #57aed3!important;
}
.icon.style5 {
  border-color: #4a87d3!important;
}
.icon.style6 {
  border-color: #6b88e6!important;
}
.button-more {
  height: 1.5em!important;
  line-height: 0.5em!important;
  padding: 0.5em 0.75em!important
}

.toggle-list-icon {
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: transform .2s ease-in-out;
  border: solid 2px rgba(255, 255, 255, 0.75);
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 4px;
  margin: 0;
  margin-top: 0.25em;
  display: inline-block;
  height: 0.75em;
  line-height: 0.75em;
  width: 0.75em;

  &.bold {
    border-color: rgba(255, 255, 255, 1);
  }
}

@import './form.scss';