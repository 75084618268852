#szkoleniaModalBody {
  width: 100%;
  height: auto;
}

/* Style the Image Used to Trigger the Modal */
#szkoleniaModalBody {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#szkoleniaModalBody {
  opacity: 1;
}

/* The Modal (background) */
.szkolenia-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.szkolenia-modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#szkoleniaModalCaption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: $color-white;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.szkolenia-modal-content, #szkoleniaModalCaption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)}
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.szkolenia-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: $color-white;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  z-index: 1000;
}

.szkolenia-close:hover,
.szkolenia-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .szkolenia-modal-content {
    width: 100%;
  }
}
