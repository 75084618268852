#banner {
  width: 100%;
  position: relative;

  > .img-responsive {
    width: 100%;
    max-height: 100vh;
  }
}

#banner-intro {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  text-align: center;
  position: absolute;
  top: 10%;

  .button {
    box-shadow: inset 0 0 0 2px $color-dark-blue;
    color: $color-dark-blue !important;
  }
}

.banner-intro {
  color: $color-dark-blue;
  margin-bottom: 0.25em;
  font-weight: bold;
}
