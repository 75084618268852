.navbar-inverse {
  background-image: -webkit-linear-gradient(#04519b, #044687 60%, #033769);
  background-image: -o-linear-gradient(#04519b, #044687 60%, #033769);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#04519b), color-stop(60%, #044687), to(#033769));
  background-image: linear-gradient(#04519b, #044687 60%, #033769);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff04519b', endColorstr='#ff033769', GradientType=0);
  -webkit-filter: none;
  filter: none;
  border-bottom: 1px solid #022241;
  background-color: #033c73;
  border-color: #022f5a;

  .navbar-toggle {
    border-color: #022f5a;

    &:hover, &:focus {
      background-color: #022f5a;
    }
  }

  .navbar-collapse, .navbar-form {
    border-color: #022a50;
  }

  .navbar-brand {
    color: #ffffff;
    margin: 5px 0;
  }

  .navbar-nav > li > a {
    color: #ffffff;
  }
}
