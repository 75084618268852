.divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
.divider h2 {
  /*font-size: 2.5em;*/
  color: #010340;
  margin-bottom: 0em;
  font-weight: bold;
}
.divider-logo {
  height: 4em;
}
