form {
  .form-group {
    width: 100%;

    .form-label {
      color: inherit;
      margin-bottom: 0;
      font-size: 100%;
    }

    .form-control {
      background-color: transparent;
      color: $font-color;
      border-color: $font-color;

      &:focus {
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
      }
    }

    .form-submit-button {
      color: $font-color;
      border-color: $font-color;
    }
  }
}
